<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader>
                <CCardTitle>Create a new User </CCardTitle>
                <div class="card-header-actions"></div>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="6">
                    <CInput
                      label="First Name"
                      placeholder="Enter first name"
                      v-model="user.first_name"
                    />
                  </CCol>
                  <CCol sm="6">
                    <CInput
                      label="Last Name"
                      placeholder="Enter last name"
                      v-model="user.last_name"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      label="Email"
                      placeholder="Enter your email"
                      type="email"
                      autocomplete="email"
                      v-model="user.email"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="6">
                    <CSelect
                      v-if="$store.getters.isAdmin"
                      placeholder="Select"
                      label="Location"
                      :options="locations"
                      :value.sync="user.location_id"
                    />
                    <CInput
                      v-if="!$store.getters.isAdmin"
                      type="hidden"
                      value="false"
                      v-model="user.location_id"
                    />
                  </CCol>
                  <CCol sm="6">
                    <CSelect
                      v-if="$store.getters.isAdmin"
                      label="Type"
                      :options="[
                        { label: 'Admin', value: 'admin' },
                        { label: 'Location Elite', value: 'location_elite' },
                        { label: 'Location Admin', value: 'location_admin' },
                        {
                          label: 'Location Regular',
                          value: 'location_regular',
                        },
                      ]"
                      :value.sync="user.type"
                    />
                    <CInput
                      v-if="!$store.getters.isAdmin"
                      type="hidden"
                      value="location_regular"
                      v-model="user.type"
                    />
                  </CCol>
                </CRow>

                <CRow>
                  <CCardHeader>
                    <CCardSubitle
                      >An e-mail will be sent to the new user to set a
                      password.</CCardSubitle
                    >
                    <div class="card-header-actions"></div>
                  </CCardHeader>
                </CRow>

                <CCardFooter>
                  <CButton
                    variant="outline"
                    type="submit"
                    color="primary"
                    @click="createUser"
                  >
                    <CIcon name="cil-check-circle" />
                    Create
                  </CButton>
                </CCardFooter>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import UserAPI from "/app/src/api/user.js";
import LocationAPI from "/app/src/api/location.js";

export default {
  name: "Create",
  data: () => {
    return {
      userAPI: new UserAPI(),
      locationAPI: new LocationAPI(),
      locations: [],
      user: {
        type: "location_regular",
      },
    };
  },
  created: function () {
    console.log("created");
    this.getLocations();
  },
  methods: {
    getLocations: function () {
      this.$store.dispatch("loading");
      let self = this;
      self.locationAPI
        .all()
        .then((result) => {
          this.$store.dispatch("stopLoading");
          for (let i = 0; i < result.length; i++) {
            self.locations.push({ label: result[i].name, value: result[i].id });
          }
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          console.log(error);
          this.$alert.show({ type: "danger", message: error });
        });
    },
    createUser: function () {
      this.$store.dispatch("loading");
      let self = this;
      this.userAPI
        .create(self.user)
        .then((result) => {
          this.$store.dispatch("stopLoading");
          self.$router.push({ path: `/manager/users/${result.id}` });
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          this.$alert.show({ type: "danger", message: error });
        });
    },
  },
};
</script>
