import Base from './base.js'

export default class User extends Base {

	constructor() {
		super();
		this.route = '/api/user'
	}

	all() {
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route, method: 'GET'})
			.then((response) => {
				// Resolve successfully
				resolve(response.data)
			})
			.catch((response) => {
				reject(self.parseErrorResponses(error.response.data))
			});
		})
	}

	create(data) {
		console.log('user creation')
		console.log(data)
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route, data: data, method: 'POST'})
				.then((response) => {
					console.log(response.data)
					resolve(response.data)
				})
				.catch((error) => {
					reject(self.parseErrorResponses(error.response.data))
				});
		})
	}

	update(data) {
		console.log('update user')
		console.log(this.route)
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route + `/${data.id}`, data: data, method: 'PUT'})
				.then((response) => {
					console.log(response.data)
					resolve(response.data)
				})
				.catch((error) => {
					reject(self.parseErrorResponses(error.response.data))
				});
		})
	}

	detail(id) {
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route + `/${id}`, method: 'GET'})
			.then((response) => {
				// Resolve successfully
				resolve(response.data)
			})
			.catch((response) => {
				reject(self.parseErrorResponses(error.response.data))
			});
		})
	}

	delete(id) {
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route + `/${id}` , method: 'DELETE'})
				.then((response) => {
					resolve(true)
				})
				.catch((response) => {
					console.log(response.error)
					reject(self.parseErrorResponses(error.response.data))
				});
		})
	}

}