var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('CCardTitle',[_vm._v("Create a new User ")]),_c('div',{staticClass:"card-header-actions"})],1),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"6"}},[_c('CInput',{attrs:{"label":"First Name","placeholder":"Enter first name"},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}})],1),_c('CCol',{attrs:{"sm":"6"}},[_c('CInput',{attrs:{"label":"Last Name","placeholder":"Enter last name"},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CInput',{attrs:{"label":"Email","placeholder":"Enter your email","type":"email","autocomplete":"email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"6"}},[(_vm.$store.getters.isAdmin)?_c('CSelect',{attrs:{"placeholder":"Select","label":"Location","options":_vm.locations,"value":_vm.user.location_id},on:{"update:value":function($event){return _vm.$set(_vm.user, "location_id", $event)}}}):_vm._e(),(!_vm.$store.getters.isAdmin)?_c('CInput',{attrs:{"type":"hidden","value":"false"},model:{value:(_vm.user.location_id),callback:function ($$v) {_vm.$set(_vm.user, "location_id", $$v)},expression:"user.location_id"}}):_vm._e()],1),_c('CCol',{attrs:{"sm":"6"}},[(_vm.$store.getters.isAdmin)?_c('CSelect',{attrs:{"label":"Type","options":[
                      { label: 'Admin', value: 'admin' },
                      { label: 'Location Elite', value: 'location_elite' },
                      { label: 'Location Admin', value: 'location_admin' },
                      {
                        label: 'Location Regular',
                        value: 'location_regular',
                      },
                    ],"value":_vm.user.type},on:{"update:value":function($event){return _vm.$set(_vm.user, "type", $event)}}}):_vm._e(),(!_vm.$store.getters.isAdmin)?_c('CInput',{attrs:{"type":"hidden","value":"location_regular"},model:{value:(_vm.user.type),callback:function ($$v) {_vm.$set(_vm.user, "type", $$v)},expression:"user.type"}}):_vm._e()],1)],1),_c('CRow',[_c('CCardHeader',[_c('CCardSubitle',[_vm._v("An e-mail will be sent to the new user to set a password.")]),_c('div',{staticClass:"card-header-actions"})],1)],1),_c('CCardFooter',[_c('CButton',{attrs:{"variant":"outline","type":"submit","color":"primary"},on:{"click":_vm.createUser}},[_c('CIcon',{attrs:{"name":"cil-check-circle"}}),_vm._v(" Create ")],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }