import Base from "./base.js"

export default class Location extends Base {
  constructor() {
    super()
    this.route = "/api/location"
  }

  all() {
    let self = this
    return new Promise(function(resolve, reject) {
      self
        .Axios({ url: self.route, method: "GET" })
        .then((response) => {
          // Resolve successfully
          resolve(response.data)
        })
        .catch((error) => {
          reject(self.parseErrorResponses(error.response.data))
        })
    })
  }

  detail(id) {
    let self = this
    return new Promise(function(resolve, reject) {
      self
        .Axios({ url: self.route + `/${id}`, method: "GET" })
        .then((response) => {
          // Resolve successfully
          resolve(response.data)
        })
        .catch((error) => {
          reject(self.parseErrorResponses(error.response.data))
        })
    })
  }

  create(data, image) {
    console.log("location creation")
    let self = this

    const formData = new FormData()

    // Add images to form data
    formData.append("name", data.name ?? "")
    formData.append("website", data.website ?? "")
    formData.append("coc_nr", data.coc_nr ?? "")
    formData.append("tax_nr", data.tax_nr ?? "")
    formData.append("note", data.note ?? "")
    formData.append("new_image", image)
    formData.append("return_time", data.return_time ?? "")
    formData.append("location_address", data.location_address ?? "")
    formData.append("location_zip_code", data.location_zip_code ?? "")
    formData.append("location_city", data.location_city ?? "")
    formData.append("location_country", data.location_country ?? "")
    formData.append("location_telephone", data.location_telephone ?? "")
    formData.append("location_mail", data.location_mail ?? "")
    formData.append("invoice_address", data.invoice_address ?? "")
    formData.append("invoice_zip_code", data.invoice_zip_code ?? "")
    formData.append("invoice_city", data.invoice_city ?? "")
    formData.append("invoice_country", data.invoice_country ?? "")
    formData.append("invoice_telephone", data.invoice_telephone ?? "")
    formData.append("invoice_mail", data.invoice_mail ?? "")
    formData.append("customers", data.customers)
    formData.append("terms", data.terms)
    formData.append("price_agreements", JSON.stringify(data.price_agreements))
    formData.append("mews_access_token", data.mews_access_token)

    return new Promise(function(resolve, reject) {
      self
        .Axios({
          url: self.route,
          data: formData,
          method: "POST",
        })
        .then((response) => {
          console.log(response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(self.parseErrorResponses(error.response.data))
        })
    })
  }

  update(data, image) {
    console.log("update location")
    let self = this

    const formData = new FormData()
    formData.append("_method", "PUT")

    // Add images to form data
    formData.append("id", data.id ?? "")
    formData.append("name", data.name ?? "")
    formData.append("website", data.website ?? "")
    formData.append("coc_nr", data.coc_nr ?? "")
    formData.append("tax_nr", data.tax_nr ?? "")
    formData.append("note", data.note ?? "")
    formData.append("image", data.image)
    formData.append("new_image", image)
    formData.append("return_time", data.return_time ?? "")
    formData.append("location_address", data.location_address ?? "")
    formData.append("location_zip_code", data.location_zip_code ?? "")
    formData.append("location_city", data.location_city ?? "")
    formData.append("location_country", data.location_country ?? "")
    formData.append("location_telephone", data.location_telephone ?? "")
    formData.append("location_mail", data.location_mail ?? "")
    formData.append("invoice_address", data.invoice_address ?? "")
    formData.append("invoice_zip_code", data.invoice_zip_code ?? "")
    formData.append("invoice_city", data.invoice_city ?? "")
    formData.append("invoice_country", data.invoice_country ?? "")
    formData.append("invoice_telephone", data.invoice_telephone ?? "")
    formData.append("invoice_mail", data.invoice_mail ?? "")
    formData.append("customers", data.customers)
    formData.append("terms", data.terms ?? "")
    formData.append("price_agreements", JSON.stringify(data.price_agreements))
    formData.append("mews_access_token", data.mews_access_token ?? "")
    formData.append("mews_service_id", data.mews_service_id ?? "")
    formData.append("mews_tax_code", data.mews_tax_code ?? "")

    return new Promise(function(resolve, reject) {
      self
        .Axios({
          url: self.route + `/${data.id}`,
          data: formData,
          method: "POST",
        })
        .then((response) => {
          console.log(response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(self.parseErrorResponses(error.response.data))
        })
    })
  }

  delete(id) {
    let self = this
    return new Promise(function(resolve, reject) {
      self
        .Axios({ url: self.route + `/${id}`, method: "DELETE" })
        .then((response) => {
          // Resolve successfully
          resolve(true)
        })
        .catch((error) => {
          reject(self.parseErrorResponses(error.response.data))
        })
    })
  }

  customers() {
    console.log("get customers for locations")
    let self = this
    return new Promise(function(resolve, reject) {
      self
        .Axios({ url: self.route + `/customers`, data: data, method: "GET" })
        .then((response) => {
          console.log(response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(self.parseErrorResponses(error.response.data))
        })
    })
  }
}
